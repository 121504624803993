import React from "react";
import Layout from "../../components/layout";
import OmerEnAyse from '../../components/portfolio/omerEnAyse.component';


export default function OmerEnAyseGalleryPage(props){
  return (
    <Layout>
    <OmerEnAyse/>
  </Layout>
  )
}



